$(window).scroll(function () {
  var _scrollTop = $(window).scrollTop()
  if (_scrollTop >= 0 && _scrollTop < 536) {
    $('.leftSideBar li')
      .eq(0)
      .addClass('active')
      .siblings()
      .removeClass('active')
  } else if (_scrollTop >= 536 && _scrollTop < 1514) {
    $('.leftSideBar li')
      .eq(1)
      .addClass('active')
      .siblings()
      .removeClass('active')
  } else if (_scrollTop >= 1514 && _scrollTop < 2174) {
    $('.leftSideBar li')
      .eq(2)
      .addClass('active')
      .siblings()
      .removeClass('active')
  } else if (_scrollTop >= 2174) {
    $('.leftSideBar li')
      .eq(3)
      .addClass('active')
      .siblings()
      .removeClass('active')
  }
})
//左侧定位到固定位置动画
// var firstRange = $('.Bg100').offset().top;
// var secondRange = $('.Bg100').offset().top;
// var thirdRange = $('.project-center').offset().top;
// var fourthRange = $('.news').offset().top;
$('.leftSideBar').on('click', 'li', function () {
  var _index = $(this).index()
  $('this')
    .addClass('active')
    .siblings('li')
    .removeClass('active')
  if (_index == 0) {
    $('html , body').animate({ scrollTop: 0 }, 'slow')
  } else if (_index == 1) {
    $('html , body').animate({ scrollTop: 536 }, 'slow')
  } else if (_index == 2) {
    $('html , body').animate({ scrollTop: 1514 }, 'slow')
  } else if (_index == 3) {
    $('html , body').animate({ scrollTop: 2174 }, 'slow')
  }
})
$(function () {
  $('.news_video').click(function () {
    // $('.mask5').addClass('active')
    // $('.video4')[0].play()
    window.open('/Answer-list')
  })
  $('.close').click(function () {
    $('.mask5').removeClass('active')
    $('.video4')[0].pause()
  })
})
